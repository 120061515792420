import firebase from 'firebase/compat';

// Initialize Firebase
const config = {
    apiKey: 'AIzaSyC-h_HAthJHkxQgorVEVSGSeGWkn_XWF2s',
    authDomain: 'sur-viv-or.firebaseapp.com',
    databaseURL: 'https://sur-viv-or.firebaseio.com',
    projectId: 'sur-viv-or',
    storageBucket: 'sur-viv-or.appspot.com',
    messagingSenderId: '776918392568',
};
firebase.initializeApp(config);

const database = firebase.database();

export function load(then) {
    database.ref('/').on('value', snapshot => {
        then(snapshot.val());
    });
}

export function addEpisode(index) {
    database.ref('/episodes/' + index.toString()).set({
        nothing: '',
    });
}

export function addVote(episodeI, voteI, description, type, pointValue) {
    if (description === '') return;

    if (pointValue === '') pointValue = 1;

    database.ref('/episodes/' + episodeI + '/votes/' + voteI).set({
        description: description,
        type: type,
        votes: ['', '', ''],
        pointValue: pointValue,
    });
}

export function submitVote(episodeI, voteI, vote, voterI) {
    if (voterI === 'admin') {
        database.ref('/episodes/' + episodeI + '/votes/' + voteI + '/result/').set(vote);
    } else {
        database.ref('/episodes/' + episodeI + '/votes/' + voteI + '/votes/' + voterI.toString()).set(vote);
    }
}