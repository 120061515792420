import React, {Component} from 'react';
import './App.css';
import * as database from './database';
import './names';
import {
    teams,
    eliminatedPlayerNames,
    tribes,
    tribeFirstEpisodes,
    voteTypes, isEliminated, currentTribes,
} from './names';
import * as C from './components';
import Slideshow from 'react-slidez';

function deepCopy(thing) {
    return JSON.parse(JSON.stringify(thing));
}

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            scores: [0, 0, 0],
            episodes: [],
            teamScores: [[]],
            admin: true,
            currentVoteI: null, //currentVoteI: [episI, voteI],
            currentVoteType: null,
            currentVoter: null,
            infoShowing: false,
        };
    }

    optionsForVote(voteType, episode) {
        switch (voteType) {
            case voteTypes.individual:
                return this.state.episodes[episode].tribesAtTime;
            case voteTypes.tribal:
                return currentTribes;
            case voteTypes.yesNo:
                return ['YES', 'NO'];
            default:
                return ['error'];
        }
    }

    countScores(data) {
        let scores = [0, 0, 0];
        let episodeI = -1;
        let tribesI = -1;
        let currentTribes;
        eliminatedPlayerNames.length = 0;

        // count episode points...
        for (let episode of data.episodes) {
            episodeI += 1; // new episode

            if (episodeI === tribeFirstEpisodes[tribesI + 1]) {
                // advance current episodes
                tribesI += 1;
                currentTribes = deepCopy(tribes[tribesI]);
            }

            // console.log('episode', episodeI, 'tribe', tribesI);

            // store a copy of the current tribes for bookkeeping
            episode.tribesAtTime = deepCopy(currentTribes);
            episode.tribesAtTime.forEach(tribe => {
                tribe.players = tribe.players.filter(name => !eliminatedPlayerNames.includes(name));
            });
            episode.eliminatedAtTime = deepCopy(eliminatedPlayerNames);

            // if there has been >0 votes...
            if (episode.votes !== undefined) {

                // go through the votes...
                for (let vote of episode.votes) {
                    // make sure we have valid data
                    if (vote === undefined) continue;
                    if (vote.votes === undefined) continue;
                    if (vote.result === undefined) continue;

                    // add a point for each correct vote
                    for (let usI of [0, 1, 2]) {
                        if (vote.votes[usI] === vote.result) {
                            scores[usI] += parseFloat(vote.pointValue);
                        }
                    }

                    // while we're here, check who was eliminatedPlayerNames this episode
                    if (vote.description === 'Tribal Council') {
                        eliminatedPlayerNames.push(vote.result);
                    }
                }

                // check for a special elimination this episode
                if (episode.specElim !== undefined) {
                    eliminatedPlayerNames.add(episode.specElim);
                }
            }
        }

        return scores;
    }

    componentDidMount() {
        // load all data and then set state
        database.load(data => {
            if (data === null) return;

            this.setState({
                scores: this.countScores(data),
            });

            // also put the whole map in there
            this.setState(data);
        });
    }

    addEpisode() {
        let newI = this.state.episodes.length;
        database.addEpisode(newI);
    }

    currentEpisode() {
        return this.state.episodes[this.state.episodes.length - 1];
    }

    startVote() {
        let description = document.getElementById('voteDescInput').value;
        let pointValue = document.getElementById('voteValueInput').value;
        let votes = this.currentEpisode().votes;
        let newI = votes === undefined ? 0 : votes.length;

        let type = undefined;
        for (let voteType of Object.keys(voteTypes)) {
            if (document.getElementById(voteType).checked) {
                type = voteType;
                break;
            }
        }

        database.addVote(
            this.state.episodes.length - 1,
            newI,
            description,
            type,
            pointValue);
    }

    // adminClick(event) {
    //     if(event.detail === 10) {
    //         this.setState({
    //             admin: true
    //         })
    //     }
    // }

    submitVote(vote) {
        database.submitVote(
            this.state.currentVoteI[0],
            this.state.currentVoteI[1],
            vote,
            this.state.currentVoter);

        this.setState({
            currentVoteType: null,
            currentVoter: null,
            currentVoteI: null,
        });
    }


    render() {

        let sortedScores = this.state.scores.slice().map(s => Number(s));
        sortedScores.sort((a, b) => a - b);
        sortedScores = sortedScores.reverse();

        let scoreSummaries = teams.map((team, i) => {
            let place = sortedScores.indexOf(this.state.scores[i]);

            let style = {
                borderBottom: '1px solid lightgrey',
                marginBottom: '-1px',
                // boxShadow: '0 4pt 14pt -6pt dimgrey',
            };

            return (
                <div className={'BoxContainer'}
                     style={style}
                     key={i}>
                    {C.PlaceTag(place)}
                    <div className={'ColumnBack'}
                         style={{backgroundColor: team.color}}/>
                    <span>{team.name}</span>
                    <div className={'ScoreDisplay'}>
                        {this.state.scores[i]}
                    </div>
                </div>
            );
        });

        scoreSummaries.push(
            <div className={'BoxContainer'}
                 key={'Result'}>
                <div id={'resultDiv'}>
                    Result
                </div>
            </div>,
        );

        let episodeSummaries = null;
        if (this.state.episodes.length > 0) {

            episodeSummaries = this.state.episodes.map((episode, episI) => {

                let votes = null;
                if (episode.hasOwnProperty('votes')) {
                    votes = episode.votes.map((vote, voteI) => {

                        let voteDone = true;
                        for (let name of vote.votes) {
                            if (name === '') {
                                voteDone = false;
                                break;
                            }
                        }

                        // make the result bubble
                        let resultBubble = C.VoteContainer(vote.result, vote.description === 'Tribal Council' ? null : undefined, 0, episode.tribesAtTime);
                        if (this.state.admin && vote.result === undefined) {
                            resultBubble = (
                                <div className={'BoxContainer'} key={'admin'}>
                                    <button className={'VoteButton'}
                                            key={'admin'}
                                            onClick={() => {
                                                this.setState({
                                                    currentVoteI: [episI, voteI],
                                                    currentVoteType: vote.type,
                                                    currentVoter: 'admin',
                                                });
                                            }}>
                                        Vote
                                    </button>
                                </div>
                            );
                        }

                        let votes = null;
                        if (vote.hasOwnProperty('votes')) {
                            votes = vote.votes.map((name, j) => {
                                if (voteDone) {
                                    // votes are done, display the votes
                                    let success = vote.hasOwnProperty('result') ? false : null;
                                    if (vote['result'] === name) {
                                        success = true;
                                    }

                                    if (success == null) success = undefined;

                                    return C.VoteContainer(name, success, j, episode.tribesAtTime);
                                }


                                // this is an active vote, display button if needed
                                let thisPersonVoted = vote.votes[j] !== '';
                                if (thisPersonVoted) {
                                    return (
                                        <div className={'BoxContainer'} key={j}>
                                            Voted&nbsp;
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className={'BoxContainer'} key={j}>
                                            <button className={'VoteButton'}
                                                    key={j}
                                                    onClick={() => {
                                                        this.setState({
                                                            currentVoteI: [episI, voteI],
                                                            currentVoteType: vote.type,
                                                            currentVoter: j,
                                                        });
                                                    }}>
                                                Vote
                                            </button>
                                        </div>
                                    );
                                }
                            });
                        }

                        // set cool background for tribal councils
                        let isCouncil = vote.description === 'Tribal Council';
                        let style = isCouncil ? {
                                background: `repeating-linear-gradient(
                                      -45deg,
                                      #606dbc00,
                                      #606dbc00 10px,
                                      rgba(228, 77, 66, .25) 10px,
                                      rgba(228, 77, 66, .25) 20px
                                    )`,
                            }
                            :
                            {};

                        let style2 = isCouncil ? {
                                fontSize: '12pt',
                            }
                            :
                            {};

                        return (
                            <div className={'Vote'}
                                 style={style}
                                 key={voteI.toString()}>
                                <div className={'VoteDescription'}
                                     style={style2}>
                                    {vote.description}
                                    {C.PointValueTag(vote.pointValue)}
                                </div>
                                {votes}
                                {resultBubble}
                            </div>
                        );
                    });

                    votes = votes.reverse();
                }

                let specElimSummary = null;
                if (episode.specElim !== undefined) {
                    specElimSummary = (
                        <div className={'Vote'}>
                            <div className={'VoteDescription'}
                                 style={{}}>
                                Medical Evacuation
                            </div>
                            <div className={'BoxContainer'} key={0}>&nbsp;&nbsp;N/A</div>
                            <div className={'BoxContainer'} key={1}>&nbsp;&nbsp;N/A</div>
                            <div className={'BoxContainer'} key={2}>&nbsp;&nbsp;N/A</div>
                            {C.VoteContainer(episode.specElim, null, 3, episode.tribesAtTime)}
                        </div>
                    );
                }

                return (
                    <div className={'Episode'}
                         key={episI.toString()}>
                        <div className={'EpisodeBack'}/>
                        <div className={'EpisodeHeader'}>
                            <div className={'EpisodeTitle'}> Episode {(episI + 1).toString()}</div>
                        </div>
                        {specElimSummary}
                        {votes}
                    </div>
                );
            });

            episodeSummaries = episodeSummaries.reverse();
        }

        let body = (
            <div id={'body'}
                 style={{
                     filter: this.state.infoShowing ? 'blur(5px)' : 'none',
                     // pointerEvents: this.state.infoShowing ? 'none' : '',
                     transitionProperty: '-moz-filter, -ms-filter, -o-filter, -webkit-filter, filter',
                     transitionDuration: '.2s',
                 }}
                 onClick={() => {
                     this.setState({
                         infoShowing: false,
                     });
                 }}>
                <div id={'scoreboard'}
                    // onClick={this.adminClick.bind(this)}
                >
                    {scoreSummaries}
                </div>
                <div id={'epiContainer'}>
                    <div id={'episodes'}>
                        {episodeSummaries}
                    </div>
                </div>
            </div>
        );

        let votePanel = null;
        if (this.state.currentVoteType !== null) {
            votePanel = (
                <div className={'VotePanel'}>
                    {
                        C.Ballot(
                            this.state.currentVoteType,
                            this.optionsForVote(this.state.currentVoteType, this.state.currentVoteI[0]),
                            this.submitVote.bind(this),
                            this.state.episodes[this.state.currentVoteI[0]].tribesAtTime)
                    }
                </div>
            );
        }

        let infoPanel = (
            <div id={'infoPanel'}
                 style={{
                     opacity: this.state.infoShowing ? '1' : '0',
                     transitionProperty: 'opacity',
                     transition: '.2s',
                     pointerEvents: this.state.infoShowing ? 'auto' : 'none',
                 }}>
                <Slideshow
                    showArrows
                    enableKeyboard
                    defaultIndex={2}
                    autoplay={false}
                    slides={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(mikeI => require('./media/mike/' + mikeI + '.jpg'))}
                    effect={'fade'}
                />
            </div>
        );

        let adminPanel = null;
        if (this.state.admin) {
            adminPanel = (
                <div id={'menu'}>
                    <div>
                        <button onClick={this.addEpisode.bind(this)}>New Episode</button>
                    </div>
                    <div>
                        <input type={'text'} id={'voteDescInput'} style={{width: '100pt'}}/>
                    </div>
                    <div>
                        <input id={voteTypes.tribal} type="radio" name="type" value={voteTypes.tribal}/> Tribal<br/>
                        <input id={voteTypes.individual} type="radio" name="type"
                               value={voteTypes.individual}/> Individual<br/>
                        <input id={voteTypes.yesNo} type="radio" name="type" value={voteTypes.yesNo}/> Yes/No<br/>
                    </div>
                    <div>
                        <input type={'text'} id={'voteValueInput'} style={{width: '50pt'}}/>
                    </div>
                    <button onClick={this.startVote.bind(this)}>New Vote</button>
                </div>
            );
        }

        let infoButton = (
            <button
                onClick={() => {
                    this.setState({
                        infoShowing: !this.state.infoShowing,
                    });
                }}
                style={{
                    zIndex: '111109999',
                }}
                id={'infoButton'}>
                Info
            </button>
        );

        return (
            <div className="App">
                <div id={'header'}>
                    <img src={require('./media/Survivor.png')}
                         alt={'Suhvoivah'}
                         id={'headerImg'}/>
                </div>
                {infoPanel}
                {adminPanel}
                {body}
                {infoButton}
                {votePanel}
            </div>
        );
    }
}

export default App;
