export let teams = [
    {
        name: 'Matty',
        color: '#b2a8d2',
    },
    {
        name: 'Lance',
        color: '#bcd6ac',
    },
    {
        name: 'Brian',
        color: '#eecaca',
    }
]

export let tribes = [
    [ // 0
        {
            name: 'Heroes',
            color: 'gold',
            players: [
                'David',
                'Paige',
                'Sam',
                'Nina',
                'Hayley',
                'Ben',
                'Flick',
                'Sharni',
                'Gerry',
                'Matt',
                'Shaun',
                'Rogue',
            ],
        },
        {
            name: 'Villains',
            color: 'violet',
            players: [
                'Simon',
                'Mimi',
                'Anjali',
                'Liz',
                'Michael',
                'Fraser',
                'Jordie',
                'Jackie',
                'Stevie',
                'George',
                'Shonee',
                'Sarah',
            ]
        },
    ], // 0
    [ // 8
        {
            name: 'Heroes',
            color: 'gold',
            players: [
                'Matt',
                'Ben',
                'George',
                'Stevie',
                'Gerry',
                'Paige',
                'Flick',
                'Shonee',
            ],
        },
        {
            name: 'Villains',
            color: 'violet',
            players: [
                'David',
                'Sam',
                'Shaun',
                'Simon',
                'Jordie',
                'Hayley',
                'Liz',
                'Nina',
            ]
        },
    ], // 8
    [ // 12
        {
            name: 'Heroes',
            color: 'gold',
            players: [
                'Matt',
                'George',
                'Stevie',
                'Gerry',
                'Flick',
                'Shonee',
                'Liz',
            ],
        },
        {
            name: 'Villains',
            color: 'violet',
            players: [
                'David',
                'Sam',
                'Shaun',
                'Simon',
                'Hayley',
                'Nina',
            ]
        },
    ], // 12
    [ // 13
        {
            name: 'Fa\'amolemole',
            color: 'limegreen',
            players: [
                'Matt',
                'George',
                'Gerry',
                'Flick',
                'Shonee',
                'Liz',
                'David',
                'Sam',
                'Shaun',
                'Simon',
                'Hayley',
                'Nina',
            ],
        },
    ], // 13
]

export let tribeFirstEpisodes = [
    0, 8, 12, 13, 999999999
]

function tribesForEpisode(episodeI) {
    let tribeI = -1
    for(let startI of tribeFirstEpisodes){
        if(episodeI >= startI) {
            tribeI += 1
        } else {
            break
        }
    }
    return tribes[tribeI]
}

export let currentTribes = tribes[tribes.length - 1].map(tribe => tribe.name)

export function Tribe(name, episode) {
    for(let tribe of tribesForEpisode(episode)){
        if(tribe.name === name) {
            return tribe
        }
    }

    return null
}

const allTribes = ['Heroes', 'Villains', 'Fa\'amolemole'];
export function isTribal(name) {
    return allTribes.includes(name)
}

export function tribeFor(player, episode) {
    for(let tribe of tribesForEpisode(episode)){
        if(tribe.players.includes(player)){
            return tribe
        }
    }
    return null
}

export let voteTypes = {
    individual: 'individual',
    tribal: 'tribal',
    yesNo: 'yesNo',
}

export let eliminatedPlayerNames = []
export function isEliminated(name) {
    return eliminatedPlayerNames.includes(name)
}

export function voteTypeFor(name){
    if(isTribal(name)) return voteTypes.tribal
    if(['YES', 'NO'].includes(name)) return voteTypes.yesNo
    return voteTypes.individual
}
