import React from 'react';
import './components.css';
import {
    isTribal,
    voteTypeFor,
    voteTypes,
    isEliminated,
} from './names';


/// A small tag which displays 1st 2nd or 3rd place
export function PlaceTag(spot) {
    let style = {
        backgroundColor: spot === 0 ? 'gold' : spot === 1 ? 'silver' : spot === 2 ? '#B87333' : 'black',
    };

    return (
        <div className={'PlaceTag'} style={style}>
            <div>
                {(spot + 1).toString()}
            </div>
        </div>
    );
}

export function PointValueTag(value) {
    if (!value && value !== 0) return null;
    return (
        <div className={'PointValueTag'}>{value.toString()}</div>
    );
}

/// a  small tag that signifies if a vote was correct or not
export function VoteTag(success) {
    let imgSrc = '';

    if (success === null) return null;

    if (success === undefined) {
        // imgSrc += 'Undefined.png'
        return null;
    } else {
        imgSrc += success ? 'Success.png' : 'Fail.png';
    }

    return (
        <img className={'VoteTag'}
             alt={''}
             src={require('./media/voteTags/vote' + imgSrc)}/>
    );
}

export function NameTag(name, tribe) {

    if (isTribal(name)) return null;

    let color = 'gray';
    if (tribe !== null && tribe !== undefined) color = tribe.color;

    let style = {
        backgroundColor: color,
    };

    return (
        <div className={'NameTag'} style={style}>{name}</div>
    );
}

function TribalExpansion(tribe) {
    console.log(tribe);
    let style = {
        backgroundColor: tribe.color,
    };

    return (
        <div className={'TribalExpansionContainer'}>
            <div className={'DownPointContainer'} style={{}}>
                <div className={'DownPoint'} style={style}/>
            </div>
            <div className={'TribalExpansion'}
                 style={style}>
                {
                    tribe.players.map((name, i) => {
                        return (
                            <div className={'SmallVoteOption'} key={i}>
                                <Portrait name={name} tribe={tribe}/>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}


class Portrait extends React.Component {
    constructor(props) {
        super(props);
        this.tribalExpand = this.tribalExpand.bind(this);
        this.tribalShrink = this.tribalShrink.bind(this);
        this.state = {
            expanded: false,
        };
    }

    tribalExpand() {
        if (this.props.preventExpand) return;
        this.setState({
            expanded: true,
        });
    }

    tribalShrink() {
        this.setState({
            expanded: false,
        });
    }

    render() {
        let name = this.props.name;


        let showEliminated = this.props.showEliminated || false;
        let tribe = this.props.tribe;

        if (!name) return null;

        let style = null;
        let innerContent = null;
        let nameTag = null;

        switch (voteTypeFor(name)) {
            case voteTypes.individual:

                style = {
                    filter: showEliminated && isEliminated(name) ? 'grayscale(100%)' : 'grayscale(0%)',
                };

                innerContent = (
                    <div className={'CastImgContainer'}>
                        <img src={require('./media/pics/' + name + '.jpg')}
                             className={'CastImg'}
                             alt={name}
                             style={style}/>
                        {/*{name} @ {tribe.name}*/}
                    </div>
                );

                nameTag = NameTag(name, tribe);
                break;

            case voteTypes.tribal:

                style = {
                    backgroundColor: tribe.color,
                    color: 'white',
                    // cursor: 'pointer',
                    position: 'relative',
                    margin: 'auto',
                };

                let tribalExpansion = null;
                if (this.state.expanded) {
                    console.log('tribe', tribe, name, showEliminated);
                    tribalExpansion = TribalExpansion(tribe);
                }

                innerContent = (
                    <div style={style}
                         className={'PortraitLabel'}
                         onMouseEnter={this.tribalExpand}
                         onMouseLeave={this.tribalShrink}
                    >
                        {name}
                        {tribalExpansion}
                    </div>
                );
                break;

            case voteTypes.yesNo:

                style = {
                    backgroundColor: name === 'YES' ? 'white' : 'slategray',
                    color: name === 'YES' ? 'slategray' : 'white',
                };

                innerContent = <div style={style} className={'PortraitLabel'}>{name}</div>;
                break;

            default:
                break;
        }

        return (
            <div className={'Portrait'} style={style}>
                {innerContent}
                {nameTag}
            </div>
        );
    }
}


/// a component that displays someone's vote and the result of that vote
export function VoteContainer(name, success, i, tribesAtTime) {

    let tribe = tribesAtTime.find(tribe => {
            return tribe.players.includes(name);
        })
        || tribesAtTime.find(tribe => {
            return tribe.name === name;
        });

    return (
        <div className={'BoxContainer'}
             key={i.toString()}>
            <Portrait name={name} showEliminated={success === null} tribe={tribe}/>
            {VoteTag(success)}
        </div>
    );
}

export function Ballot(type, options, submitFunction, tribesAtTime) {

    if (type === voteTypes.individual) {

        // organize individuals into tribes
        let voteGroups = options.map((tribe, i) => {
            return (
                <div className={'VoteGroup'}>
                    <Portrait name={tribe.name} tribe={tribe} preventExpand={true}/>
                    <div className={'VoteGroupOptionContainer'}>
                        {
                            Ballot(null, tribe.players, submitFunction, tribesAtTime)
                        }
                    </div>
                </div>
            );
        });

        return (
            <div>
                {voteGroups}
            </div>
        );
    }

    return options.map((option, i) => {
        let tribe = tribesAtTime.find(tribe => {
                return tribe.players.includes(option);
            })
            || tribesAtTime.find(tribe => {
                return tribe.name === option;
            });

        // type is null on inner Ballot pass for individual vote type
        let className = type === null ? 'SmallVoteOption' : 'VoteOption';

        return (
            <div className={className} key={i} onClick={() => submitFunction(option)}>
                <Portrait name={option} tribe={tribe}/>
            </div>
        );
    });
}
